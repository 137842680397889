<template>
  <div>
    <van-nav-bar :title="$route.meta.title" left-arrow @click-left="toStudent" />
    <van-tabs v-model="active" sticky @click="handleChangeTab">
      <van-tab v-for="(sdt, idx) in studentList" :key="idx" :title="sdt.cnName">
        <SignInOutLogList :student-id="sdt.id" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import guardianApi from '@/api/guardian'

import SignInOutLogList from './components/SignInOutLogList.vue'
export default {
  components: {
    SignInOutLogList
  },
  data() {
    return {
      appId: '',
      active: 0,
      studentList: [],
      msg: '签到记录'
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.initDataList()
  },
  mounted() {},
  methods: {
    initDataList() {
      guardianApi.studentList().then(res => {
        if (res.succ) {
          this.studentList = res.data.items
        }
      })
    },
    handleChangeTab(idx, title) {
      console.log(idx, title)
    },
    toStudent() {
      this.$router.push(`/${this.appId}/guardian`)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
