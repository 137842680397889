<template>
  <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="initDataList">
    <van-cell v-for="item in dataList" :key="item.id" :title="'时间：'+item.createTime">
      <template #label>
        <div>班级：{{ item.clazz.cnName }}</div>
        <div>类别：{{ item.signType | formatSignType }}</div>
      </template>
    </van-cell>
  </van-list>
</template>
<script>
import guardianApi from '@/api/guardian'
export default {
  props: {
    studentId: {
      type: Number,
      default: -1
    },
    signType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      dataList: [],
      maxId: null,
      loading: false,
      finished: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    doSearch(keyword) {
      this.searckKey = keyword
      this.reloadDataList()
    },
    initDataList() {
      guardianApi.signInOutList({ maxId: this.maxId, studentId: this.studentId }).then(res => {
        if (res.succ) {
          let items = res.data.items
          if (items.length === 0) {
            this.finished = true
          } else {
            for (let i = 0; i < items.length; i++) {
              this.dataList.push(items[i])
            }
            this.loading = false
            this.maxId = items[items.length - 1].id
          }
        }
      })
    },
    reloadDataList() {}
  }
}
</script>
<style lang="less" scoped>
</style>
